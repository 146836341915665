import React from "react";
import "./styles.css";

const Loader2D = ()=>{

    return(
        <div className="loaderContainer">
        <div className="twoDLoader"></div>
        </div>
    )
}

export default Loader2D